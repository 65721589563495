.button_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

.click {
    font-family: var(--font-primary);
    font-size: 1.75rem;
    padding: 0.75rem 2.25rem;
    border: 0.15rem;
    border-radius: 25rem;
    border-style: solid;
    margin: 3rem;
    border-color: var(--color-primary);
}

.button_shadow {
    color: none;
    background-color: var(--color-primary);

    position: absolute;
}

.button {
    color: var(--color-primary);
    background-color: var(--color-secondary);
    border-color: var(--color-primary);

    position: relative;
    z-index: 1;
    top: -6px;
    right: 4px;
    transition: top 400ms cubic-bezier(0.9, 0, 0.1, 2), right 400ms cubic-bezier(0.9, 0, 0.1, 2), color 200ms ease;
}

.button:hover {
    color: var(--color-light);

    top: -11px;
    right: 5px;

    transition: top 400ms cubic-bezier(0.9, 0, 0.1, 2), right 400ms cubic-bezier(0.9, 0, 0.1, 2), color 200ms ease;
}

.button:active {
    color: var(--color-light);

    top: 1px;
    right: 0px;

    transition: top 400ms cubic-bezier(0.9, 3, 0.1, 0), right 400ms cubic-bezier(0.9, 3, 0.1, 0);
}

/* ============= MEDIA QUERIES (LARGE DEVICES) ==================== */
@media screen and (max-width: 1500px) {

}


/* ============= MEDIA QUERIES (MEDIUM DEVICES) ==================== */
@media screen and (max-width: 1024px) {

    .click {
        font-size: 1.25rem;
        padding: 0.75rem 2rem;
        border: 0.15rem;
        border-radius: 25rem;
        border-style: solid;
        margin: 2rem;
    }
}

/* ============= ADDTIONAL (MEDIUM TO SMALL) ==================== */
@media screen and (max-width: 800px) {
    .click {
        font-size: 1rem;
        padding: 0.5rem 1.5rem;
        border: 0.15rem;
        border-radius: 25rem;
        border-style: solid;
    }
    
}

/* ============= MEDIA QUERIES (SMALL DEVICES) ==================== */
@media screen and (max-width: 600px) {

    .click {
        font-size: 0.75rem;
        padding: 0.5rem 1rem;
        border: 0.1rem;
        border-radius: 25rem;
        border-style: solid;
        margin: 1rem;
        border-color: var(--color-primary);
    }

    .button {
        top: -0.2rem;
        left: -0.05rem;
    }

    .button:hover {
        top: -0.5rem;
        left: -0.1rem;
    }

    .button:active {
        top: 0rem;
        left: 0rem;
    }
}

