.case_study {
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: var(--color-white);
    cursor: var(--cursor-butterfly);
}

.case_studies {
    width: 1100px;
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding-top: 30px;
}

.work_preview {
    height: 247.5px;
    width: 330px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--color-light);
    margin-bottom: 60px;
    border-radius: 0.5rem;
    transition: all 200ms cubic-bezier(0.3, 1, 0.9, 1);
}

.work_thumbnail {
    width: 330px;
    height: 247.5px;
    border-radius: 0.5rem;
    transition: all 200ms cubic-bezier(0.3, 1, 0.9, 1);
    cursor: var(--cursor-butterfly);
}

.work_title {
    opacity: 0;
    width: 300px;
    text-align: center;
    vertical-align: middle;
    position: relative;
    top: -49%;
    transition: all 200ms cubic-bezier(0.3, 1, 0.9, 1);
}

.work_preview:hover > .work_thumbnail {
    opacity: 5%;
}

.work_preview:hover > .work_title {
    width: 300px;
    top: -50%;
    transform: translatey(-50%);
    opacity: 100%;
}

/* ============= MEDIA QUERIES (LARGE DEVICES) ==================== */
@media screen and (max-width: 1500px) {
    .case_studies {
        width: 1000px;
    }

    .work_preview {
        width: 280px;
        height: 210px;
    } 
    
    .work_thumbnail {
        width: 280px;
        height: 210px;
    }

    .work_title {
        width: 230px;
    }

    .work_preview:hover > .work_title {
        width: 230px;
    }
}

/* ============= MEDIA QUERIES (MEDIUM DEVICES) ==================== */
@media screen and (max-width: 1024px) {
    .case_studies {
        width: 800px;
    }

    .work_preview {
        width: 300px;
        height: 225px;
    } 
    
    .work_thumbnail {
        width: 300px;
        height: 225px;
    }
}

/* ============= ADDTIONAL (MEDIUM TO SMALL) ==================== */
@media screen and (max-width: 800px) {
    .case_studies {
        width: 550px;
        padding-top: 15px;
    }

    .work_preview {
        width: 250px;
        height: 187.5px;
    } 

    .work_thumbnail {
        width: 250px;
        height: 187.5px;
        margin-bottom: 30px;
    }

    .work_preview:hover > .work_title {
        top: -65%;
    }

}

/* ============= MEDIA QUERIES (SMALL DEVICES) ==================== */
@media screen and (max-width: 600px) {
    .case_studies {
        width: 300px;
        padding-top: 30px;
    }

    .work_preview {
        width: 300px;
        height: 225px;
    } 

    .work_thumbnail {
        width: 300px;
        height: 225px;
        margin-bottom: 60px;
    }

    .work_preview:hover > .work_title {
        top: -75%;
    }
    
}