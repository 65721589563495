.about {
    cursor: var(--cursor-butterfly);
    overflow: hidden;
    margin: auto;

}

.chat_box {
    display: flex;
    justify-content: center;
    align-items: center;
}

.headshot {
    width: 18%;
    margin-right: 5%;
}

.debbie {
    width: 18%;
    margin-left: 5%;
}



/* ============= MEDIA QUERIES (SMALL DEVICES) ==================== */
@media screen and (max-width: 600px) {
    .chat_box {
        flex-direction: column;
        margin-bottom: 50px;
    }

    .headshot {
        width: 30%;
    }

    .debbie {
        width: 30%;
        position: relative;
        bottom: 50px;
    }

    .about_copy {
        text-align: center;
    }

    .debbie_copy {
        text-align: center;
        position: relative;
        bottom: -180px;
    }
}