.project_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 60px;
    cursor: var(--cursor-butterfly);
}

.project_image {
    width: 1000px;
    margin-bottom: 50px;
}

.project_imageS {
    width: 500px;
}

.project_video {
    width: 1000px;
    height: 562.5px;
}

.project_videoV {
    width: 540px;
    height: 960px;
}

.project_lockup {
    width: 1000px;
}


.credits {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.project_image4x3 {
    width: 1000px;
    padding: 50px 150px;
}

.thankYou {
    width: 500px;
}

/* ============= MEDIA QUERIES (LARGE DEVICES) ==================== */
@media screen and (max-width: 1500px) {
    .project_container {
        gap: 50px;
    }

    .project_image {
        width: 800px;
    }

    .project_video {
        width: 800px;
        height: 450px;
    }

    .project_lockup {
        width: 800px;
    }

    .thankYou {
        width: 400px;
    }
}

/* ============= MEDIA QUERIES (MEDIUM DEVICES) ==================== */
@media screen and (max-width: 1024px) {
    .project_container {
        gap: 40px;
    }

    .project_image {
        width: 600px;
        margin-bottom: 20px;
    }

    .project_video {
        width: 600px;
        height: 337.5px;
    }

    .project_lockup {
        width: 600px;
    }

    .project_videoV {
        width: 360px;
        height: 640px;
    }

    .project_image4x3 {
        width: 600px;
        padding: 20px 50px;
    }

    .thankYou {
        width: 300px;
    }
}

/* ============= ADDTIONAL (MEDIUM TO SMALL) ==================== */
@media screen and (max-width: 800px) {
    .project_image {
        width: 500px;
    }

    .project_video {
        width: 500px;
        height: 281.25px;
    }

    .project_lockup {
        width: 500px;
    }

    .project_videoV {
        width: 300px;
        height: 533px;
    }

    .project_image4x3 {
        width: 500px;
    }

    .thankYou {
        width: 250px;
    }
}

/* ============= MEDIA QUERIES (SMALL DEVICES) ==================== */
@media screen and (max-width: 600px) {
    .project_container {
        gap: 30px;
    }

    .project_image {
        width: 350px;
        margin-bottom: 15px;
    }

    .project_video {
        width: 350px;
        height: 197px;
    }

    .project_lockup {
        width: 300px;
    }

    .project_videoV {
        width: 270px;
        height: 480px;
    }

    .project_image4x3 {
        width: 350px;
        padding: 15px 50px;
    }

    .thankYou {
        width: 150px;
    }
}