.loading {
    background-color: var(--color-light);
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin: auto;
    width: 100%;
    height: 100vh;
}

.loadingGIF {
    width: 15%;
}

.butterflies{
    position: absolute;
    margin-top: 0px;
}