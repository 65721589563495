@import url('https://fonts.googleapis.com/css2?family=Carter+One&family=Roboto:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&display=swap');

.note {
        text-align: center;
        margin-top: 8rem;
}

* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}

:root {
    --color-primary: #294339;
    --color-secondary: #F89FE1;
    --color-light: #FBF4EB;
    --color-white: #ffffff;
    --color-lightGreen: #4ab982;

    --font-primary: 'Carter One', sans-serif;
    --font-secondary: 'Open Sans', sans-serif;

    --cursor-butterfly: url('../src/assets/Butterfly_cursor.gif'), auto;

    --transition: all 200ms ease;
}

html {
    scroll-behavior: smooth;
}


body {
    font-family: var(--font-primary);
    color: var(--color-primary);
    line-height: 1.7;
}


/* ============= GENERAL STYLES ==================== */

h1 {
    font-size: 1.75rem;
    color: var(--color-primary);
}

p {
    font-size: 0.875rem;
    color: var(--color-primary);
    margin-top: 0px;
}

h3 {
    color: var(--color-primary);
    font-size: 1.5rem;
}

h4 {
    font-family: 'Open Sans';
    font-size: 1.25rem;
}

a {
    color: var(--color-primary);
    font-size: 1.5rem;
    transition: var(--transition);
    cursor: pointer;
    text-decoration: none;
}

a:hover {
    color: var(--color-secondary);
    cursor: pointer;
}

button {
    cursor: pointer;
}

section {
    padding-top: 200px;
}

.firstSecton {
    padding-top: 115px;
}

footer {
    margin-top: 200px;
}

img {
    max-width: 1500px;
}

/* ============= MEDIA QUERIES (LARGE DEVICES) ==================== */
@media screen and (max-width: 1500px) {

    h1 {
        font-size: 1.5rem;
    }

    h4 {
        font-family: 'Open Sans';
        font-size: 1.1rem;
    }

    a{
        font-size: 1.25rem;
    }

    p {
        font-size: 0.75rem;
    }

    section {
        padding-top: 150px;
    }

    .firstSecton{
        padding-top: 96px;
    }

    footer {
        margin-top: 150px;
    }
}


/* ============= MEDIA QUERIES (MEDIUM DEVICES) ==================== */
@media screen and (max-width: 1024px) {
    h1 {
        font-size: 1.25rem;
    }

    h4 {
        font-size: 1rem;
    }

    a{
        font-size: 1.15rem;
    }

    section {
        padding-top: 100px;
    }

    .firstSecton{
        padding-top: 77px;
    }

    footer {
        margin-top: 100px;
    }
}

/* ============= ADDTIONAL (MEDIUM TO SMALL) ==================== */
@media screen and (max-width: 800px) {
    h1 {
        font-size: 1rem;
    }

    h4 {
        font-size: 0.9rem;
    }

    a{
        font-size: 0.75rem;
    }

    section {
        padding-top: 80px;
    }

    .firstSecton{
        padding-top: 58px;
    }

    footer {
        margin-top: 80px;
    }
}

/* ============= MEDIA QUERIES (SMALL DEVICES) ==================== */
@media screen and (max-width: 600px) {
    h1 {
        font-size: 0.75rem;
    }

    h4 {
        font-size: 0.75rem;
    }

    a{
        font-size: 0.65rem;
    }

    p {
        font-size: 0.2rem;
    }

    section {
        padding-top: 50px;
    }

    .firstSecton{
        padding-top: 48px;
    }

    footer {
        margin-top: 50px;
    }

    .note {
        margin-top: 3rem;
}

}

