.work_slot {
    width: 350px;
    height: 400px;
    border: 2px solid #294339;
    border-radius: 5px;
    background-color: #ffffff;
    box-shadow: 3px 3px 0 #294339;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    transition: height 600ms cubic-bezier(0.3, 1, 0.9, 1), box-shadow 600ms ease;
    cursor: pointer;
}

.work_slot:hover {
    height: 385px;
    box-shadow: 1px 13px 0 #294339;
}

.work_slot:hover > .work_image {
    height: 290px;
}


.work_image {
    width: 300px;
    height: 300px;
    border-radius: 5px;
    margin: 25px;
    transition: all 200ms cubic-bezier(0.3, 1, 0.9, 1);
    overflow: hidden;
}


/* ============= MEDIA QUERIES (MEDIUM DEVICES) ==================== */
@media screen and (max-width: 1500px) {
    .work_slot {
        width: 260px;
        height: 300px;
        box-shadow: 2px 2px 0 #294339;
    }

    .work_slot:hover {
        height: 285px;
        box-shadow: 1px 13px 0 #294339;
    }

    .work_slot:hover > .work_image {
        height: 220px;
    }

    .work_image {
        margin: 15px;
        width: 230px;
        height: 230px;
    }
}

/* ============= MEDIA QUERIES (SMALL DEVICES) ==================== */
@media screen and (max-width: 600px) {
    .work_slot {
        width: 150px;
        height: 180px;
        box-shadow: 1px 1px 0 #294339;
        border-radius: 2.5px;
        border: 1.5px solid #294339;
    }

    .work_slot:hover {
        height: 170px;
        box-shadow: 1px 5px 0 #294339;
    }

    .work_slot:hover > .work_image {
        height: 118px;
    }

    .work_image {
        width: 120px;
        height: 120px;
        margin: 15px;
    }
}