nav {
    width: 1200px;
    height: 115px;
    position: fixed;
    top: 0;
    left: 50%;
    transform: translatex(-50%);
    z-index: 5;

    background-image: url('../../assets/NavBar.png');
    background-position-x: center ;
    background-repeat: no-repeat;
    background-size: cover;
}

.nav_text {
    margin: auto;
    margin-top: 50px;
    max-width: 1500px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.logo {
    margin-left: 8%;
    background-image: url('../../assets/SISI_logo_Full_light.gif');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 60px;
    width: 106px;
    position: relative;
    bottom: 4px;
    transition: all 200ms ease;
}

.logo:hover {
    background-image: url(../../assets/SISI_logo_Full_pink.gif);
}

.nav_list {
    display: flex;
    float: right;
    gap: 5rem;
    padding-right: 8%;
}

.nav_link {
    color: var(--color-white);
}

/* ============= MEDIA QUERIES (LARGE DEVICES) ==================== */
@media screen and (max-width: 1500px) {
    nav {
        width: 1000px;
        height: 96px;
    }

    .nav_text {
        margin-top: 35px;
    }

    .nav_list {
        gap: 4rem;
    }
}


/* ============= MEDIA QUERIES (MEDIUM DEVICES) ==================== */
@media screen and (max-width: 1024px) {
    nav {
        width: 800px;
        height: 77px;
    }

    .nav_text {
        margin-top: 25px;
    }

    .nav_list {
        gap: 3rem;
    }

    .logo {
        height: 50px;
        width: 90px;
        bottom: 2px;
    }
}

/* ============= ADDTIONAL (MEDIUM TO SMALL) ==================== */
@media screen and (max-width: 800px) {
    nav {
        width: 600px;
        height: 58px;
    }

    .nav_text {
        margin-top: 25px;
    }

    .nav_list {
        gap: 2rem;
    }

    .logo {
        height: 25px;
        width: 44px;
        bottom: 0.5px;
    }
}

/* ============= MEDIA QUERIES (SMALL DEVICES) ==================== */
@media screen and (max-width: 600px) {
    nav {
        width: 360px;
        height: 34px;
    }

    .nav_text {
        margin-top: 10px;
    }

    .nav_list {
        gap: 2rem;
    }
}
