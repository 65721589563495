.play {
    overflow: hidden;
    margin: auto;
    cursor: var(--cursor-butterfly);

    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.butterflies {
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    position: absolute;
}

.play_container {
    max-width: 800px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-items: center;
}

.play_gifs {
    display: block;
    width: 100%;
    max-width: 1500px;
    object-fit: cover;
}

/* ================ EMAIL ================ */
.function {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;

    position: absolute;
    gap: 8rem;
    right:1rem;
    top: 26rem;
}

.email {
    color: var(--color-primary);
    transform: rotate(90deg);
}


/* ================ SOCIALS ================ */
.socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    cursor: pointer;

    position:absolute;
    left: 6rem;
    top: 25rem;
}

.social_icon {
    color: var(--color-primary);
}

.socials::after,
.socials::before{
    content: "";
    width: 2px;
    height: 3rem;
    background: var(--color-primary);
}

/* ============= MEDIA QUERIES (LARGE DEVICES) ==================== */
@media screen and (max-width: 1500px) {

    .play_container {
        max-width: 700px;
    }

    .function {
        gap: 6rem;
        top: 20rem;
    }

    .socials {
        top: 20rem;
    }
}

/* ============= MEDIA QUERIES (MEDIUM DEVICES) ==================== */
@media screen and (max-width: 1024px) {

    .play_container {
        max-width: 500px;
        margin: auto;
    }

    .function {
        gap: 5rem;
        top: 15rem;
    }

    .socials {
        top: 13rem;
    }
}

/* ============= ADDTIONAL (MEDIUM TO SMALL) ==================== */
@media screen and (max-width: 800px) {

    .play_container {
        max-width: 400px;
    }

    .function {
        gap: 3rem;
        top: 10rem;
        right: 1.5rem;
    }

    .socials {
        top: 10rem;
        left: 5rem;
        gap: 0.75rem;
    }

    .socials::after,
    .socials::before{
        content: "";
        width: 1px;
        height: 1.5rem;
    }
}

/* ============= MEDIA QUERIES (SMALL DEVICES) ==================== */
@media screen and (max-width: 600px) {

    .play_container {
        max-width: 300px;
        margin: auto;
    }

    .function {
        gap: 3rem;
        top: 8rem;
        right: -1rem;
    }

    .socials {
        top: 8rem;
        left: 1.5rem;
        gap: 0.5rem;
    }

    .socials::after,
    .socials::before{
        content: "";
        width: 1px;
        height: 1rem;
    }
}


